/*  */
div.scrollmenu {
  background-color: #fff !important;
  overflow: auto;
  white-space: nowrap;
  
}
.scrollmenu::-webkit-scrollbar {
  display: none;
}
.scrollmenu{
    cursor:pointer;
}
.chip-css {
  width: 150px;
  cursor:pointer;
}

/*  menu */
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
 
}

li a {
  display: block;
  color:#000 !important;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover:not(.active) {
  background-color: #FFf !important;
}

.active{
    /* background-color: #FFF !important; */
    /* color:#EB0003 !important; */
}

/* tab */
.TabIndicatorProps {
  left: 87.8px !important;
  width: 83.8px !important;
  height: 2px;

}