.title-box {
  display: flex;
  align-items: center;
  width: 100%;
}

.title {
  padding: 0;
  font-weight: normal;
  font-size: 1rem;
  color: #fff;
}
.back-icon {
  width: 3.125rem;
  height: 1.875rem;
  color: white;
}