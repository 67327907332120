.payment-box {
    /* border: 0.125rem solid #cccccc6b; */
    /* filter: drop-shadow(1px 3px 3px rgba(0, 0, 0, 0.25)); */
    background-color: #fff;
    border-radius: 4px;
  }
  
  .bank-card {
    background-color: #005faf;
    width: 4.5rem;
    height: 2.85rem;
  }