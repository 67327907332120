.order-detail {
    /* margin: 0 0.75rem; */
    border: 0.125rem solid #fff;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
    /* min-height: 20rem; */
    background-color: #fff;
    border-radius: 4px;
}

.order-title {
    font-size: 1rem;
    margin: 0.75rem 1rem;
}

.item-box {
    margin: 0.75rem 0.75rem;
}

.item-box1 {
    padding: 4px 0.75rem;
}

.flex-box {
    display: flex; 
    justify-content:flex-start;
    /* margin-bottom: 0.5rem; */
    border:'2px solid'
}
.cart-card{    
    margin-top: -7px;
}
.cart-check {
    margin-top: 38px;
    margin-left: -7px;
}
.select-all{
    height:52px;
   
}
.order-img {
    width: 4.5rem;
    height: 4.75rem;
}

.items-full {
    margin: 0 10px;
    line-height: 1.25rem;
}

.item-box-title, .item-quantity, .item-variant {
    font-size: 0.8rem;
}

.item-box-title {
    font-weight: normal;
}

.item-variant {
    display: inline-block;
    /* padding: 0.125rem 0.25rem; */
    /* background-color: #EFEFEF; */
    border-radius: 0.125rem;
}

.item-quantity {
    color: var(--light-gray);
}

/* .item-optional-msg {
    width: 100%;
    border-width: 0 0 1px;
    border-color: var(--light-gray);
    height: 24px;
    padding-left: 10px;
} */

.filter-border-box {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
    background-color: #fff;
    border-top: 0.125rem solid #8B8B8B;
    border-bottom: 0.125rem solid #8B8B8B;
}

.css-p57y9f, .MuiButtonGroup-grouped {
    min-width: 32px !important;
    height: 30px !important;
    /* box-shadow: none ; */
}
.filter-border-box {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
    background-color: #fff;
    border-top: 0.125rem solid #8B8B8B;
    border-bottom: 0.125rem solid #8B8B8B;
}

.filter-btn {
    background-color: #fff;
    border: none;
    border-right: 0.125rem solid #8B8B8B;
    border-left: 0.125rem solid #8B8B8B;
}