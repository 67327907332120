.main {
    display: inline-block;
    width: 100%;
    padding: 38px 0;
    background: #fff;
}
@media (min-width: 1366px)
{
    .main-container {
        max-width: 1260px;
        /* max-width: 1200px; */
    }
}


.main-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.slug-title {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    color: #333;
    font-size: 30px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
}
a{
    color: blue !important;
}